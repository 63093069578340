<template>
  <div id="wrapper">
    <div id="over_map">
      <card-component class="has-table">
        <b-loading :active="loading" :is-full-page="false">
        </b-loading>
          <transition name="fade" :duration="200" mode="out-in">
            <router-view name="side_view"/>
          </transition>

      </card-component>
    </div>
    <section class="section">
      <div id="container">
        <div id='map' class='map' style="height: 100vh">
        </div>
      </div>
    </section>

  </div>

</template>

<script>
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css'
import MapboxDraw from '@mapbox/mapbox-gl-draw'
import Mapbox from 'mapbox-gl-vue'
import { MapboxStyleSwitcherControl } from "mapbox-gl-style-switcher";
import "mapbox-gl-style-switcher/styles.css";
import * as markers from '../markerStyles.js'
import settings from "@/store/modules/settings.store";
import mapbox from "@/store/modules/mapbox.store";
import deplo from "../store/deplo.store";
import CardComponent from '@/components/CardComponent'

export default {
  name: "map2",
  components: {
    Mapbox,
    CardComponent
  },
  data () {
    return {
      accessToken: 'pk.eyJ1IjoibHVja2FzZGMiLCJhIjoiY2sxdWR6d3B5MDkwOTNpcGFuNXh3dGhzNSJ9.nBYgv6LPBGFGDx56p8mRQQ',
      mapStyle: {
        style: 'mapbox://styles/luckasdc/ck7lt4eao0up71in4wu61e03u'
      },
      publicPath: process.env.BASE_URL,
      devices: {
      },

      selected: null,
      isCreating: false,

    }
  },

  ready () {
    // We need to set mapbox-gl library here in order to use it in template
    //this.mapbox = Mapbox
  },

  created() {
    if(!settings.isRegistered) {
      this.$store.registerModule('settings', settings)
      settings.isRegistered = true
    }
    if(!mapbox.isRegistered) {
      this.$store.registerModule('mapbox', mapbox)
      mapbox.isRegistered = true
    }
    if(!deplo.isRegistered) {
      this.$store.registerModule('deplo', deplo)
      deplo.isRegistered = true
    }

    // this.$store.commit('settings/asideRightToggle', false)
    // this.$store.commit('settings/asideMobileStateToggle', false)
    //this.$store.commit('settings/fullPage', true)
    this.$store.commit('settings/asideRightToggle', false)
    this.$store.commit('settings/navBarOnly', true)


  },
  mounted() {
    this.$store.dispatch('deplo/loadMap')
  },


  computed: {
    loading() {
      return this.$store.state.deplo.sidepanelLoading
    }
  },

  methods: {

    mapInitialized: function (map) {
      this.themap = map
      this.themap.addControl(new MapboxStyleSwitcherControl());

      let thisIns = this;
      this.themap.loadImage(
          'https://docs.mapbox.com/mapbox-gl-js/assets/popup.png',
          function (error, image) {
            if (error) throw error;

            // Add the image to the map style.
            thisIns.themap.addImage('popup', image, {
              stretchX: [
                [25, 55],
                [85, 115]
              ],
                  stretchY: [[25, 100]],
                  content: [20, 20, 115, 100],
                  pixelRatio: 2
            })
          })

      // Add controls to the map
      this.themap.addControl(this.draw, 'top-right')
      // Add event functions
      this.themap.on('draw.create', (e) => {
        console.log('creating device...')
        this.createDevice(e)
      })
      this.themap.on('draw.update', (e) => {
        console.log('moving devices...')
        this.moveDevices(e)
      })
      this.themap.on('draw.delete', (e) => {
        console.log('deleting devices...')
        this.deleteDevices(e)
      })
      this.themap.on('draw.selectionchange', (e) => {
        if (!this.isCreating) {
          console.log('selecting devices...')
          this.selectDevices(e)
        }
      })
    },
    async fetchDevices () {
      let thisIns = this
      this.$store.dispatch("deplo/fetchAllMapItemsGeo").then(response => {
        thisIns.draw.set(response.data)

      }).catch((error) => {
        this.$buefy.toast.open({
          message: `Error: ${error.message}`,
          type: 'is-danger',
          position: 'is-bottom',
          queue: false
        })
      })
      this.$store.dispatch("deplo/fetchAllMapItems")
      this.$store.dispatch("deplo/fetchAllCategories")
    },

    async moveDevices (e) {
      for (let i = 0; i < e.features.length; i++) {
        let updated_item = {
          id: e.features[i].properties.mapitem_id,
          point: JSON.stringify(e.features[i].geometry)
        }
        this.$store.dispatch('deplo/updateMapItem', updated_item).then(response => {
          this.$buefy.toast.open({
            message: 'Item moved',
            type: 'is-success',
            queue: false
          })
        })
      }
    },
    async deleteDevices (e) {
      for (let i = 0; i < e.features.length; i++) {
        this.$store.dispatch('deplo/deleteMapItem', e.features[i].properties.mapitem_id).then(response => {
          this.$router.push({ name: 'mapitems.overview', replace: true })
          this.$buefy.toast.open({
            message: 'Item deleted',
            type: 'is-success',
            queue: false
          })
        })
      }
    },
    selectDevices (e) {
      this.isCreating = false
      this.selected = e
      if(e.features.length === 1) {
        this.themap.easeTo({center: e.features[0].geometry.coordinates, zoom: 18.5, padding: -200})
        if(e.features[0].properties.mapitem_id) {
          this.$router.push({ name: 'mapitems.detail', params: {id: e.features[0].properties.mapitem_id}, replace: true })
        }
      }
      else {
        this.$router.push({ name: 'mapitems.overview', replace: true })

      }

    },
    async createDevice (e) {
      await this.$store.dispatch('deplo/panelLoading')
      this.$store.dispatch('deplo/createMapItem',
          {
            point: JSON.stringify(e.features[0].geometry),
            area_id: 1, // TODO
            project_id: 2, // TODO
            category_id: 1, // TODO
            status_id: 1
          }
      ).then(resp => {
        this.draw.setFeatureProperty(e.features[0].id, 'mapitem_id', resp.data.data.id)
        this.draw.setFeatureProperty(e.features[0].id, 'name', resp.data.data.name)
        this.draw.add(this.draw.get(e.features[0].id))
        this.$router.push({ name: 'mapitems.detail', params: {id: resp.data.data.id}, replace: true })
        this.$store.dispatch('deplo/panelLoadingComplete')
      })

    }
  }
}
</script>

<style scoped>

#map {
  width: 100%;
  height: 100%;
}

.section{
  height: 100vh;
  padding: 0;
  padding-top: 0;
}
#wrapper {
  position: relative;
}
#over_map {
  position: absolute;
  top: 40px;
  left: 40px;
  width: 30%;
  z-index: 3; }
</style>
